import React from "react"
import Wrapper from "../shared/wrapper"
import FacebookLoginWrapper from "../facebook_login_wrapper"
import { navigate } from "gatsby"
import ZiptiButton from "../shared/zipti_button"
import ZiptiText from "../shared/zipti_text"
import { Container } from "@chakra-ui/react"

const ExpertSignup = (props: any) => {
  return (
    <Wrapper>
      <Container centerContent h="60vh">
        <ZiptiText mt="3vh" fontWeight="bold" fontSize="2.5vh">
          Become a Zipti Expert
        </ZiptiText>
        <FacebookLoginWrapper
          onLogin={() => {
            navigate("/edit_profile ")
          }}
        >
          <ZiptiButton mt="2vh" w="50vw">
            Access through Facebook
          </ZiptiButton>
        </FacebookLoginWrapper>
      </Container>
    </Wrapper>
  )
}

export default ExpertSignup
